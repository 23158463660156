import { DataAccessService } from './../data-access.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectComponent } from 'ng-select';

@Component({
  selector: 'app-debit-note',
  templateUrl: './debit-note.component.html',
  styleUrls: ['./debit-note.component.scss']
})
export class DebitNoteComponent implements OnInit {
  @ViewChild('prod') selectComponent: SelectComponent;
  debitnotes;
  return_to = 'Debit Note';
  isFirst = true;
  products;
  batches;
  distributors;
  batch_nos = [];
  distributors1 = [];
  invoice_date;
  invoice_no;
  exp_date;
  t_qty = 0;

  returnProducts = [];
  index = 0;

  tempList = {};
  p_name;
  batch_no;
  distributor_name;
  return_qty = 0;
  reason = 'Expiry';
  isSecond = false;
  debitNotes1 = [];
  prodName = '';
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getDebitNotes();
    this.getDetails();
    
  }

  getDebitNotes() {
    this.service.get('return.php?type=getDebitNotes').subscribe(response => {
      this.debitnotes = response;
      this.debitNotes1 = this.debitnotes;
    });
  }

  getDetails() {
    this.distributor_name = '';
    this.batch_no = '';
    this.t_qty = 0;
    this.exp_date = '';
    this.invoice_date = '';
    this.invoice_no = '';
    this.service.get('return.php?type=getProducts').subscribe( response => {
      this.products = response;
    });
  }

  getBatches(event) {
    this.prodName = event.value;
    this.service.get('return.php?type=getBatches&p_name=' + event.value).subscribe(response => {
      this.batches = response;
    });
  }

  getDistributors(index) {
    index = index - 1;
    this.t_qty = this.batches[index].availableQty;
    this.exp_date = this.batches[index].expiry;
    // tslint:disable-next-line:max-line-length
    this.service.get('return.php?type=getDistributors&p_name=' + this.prodName + '&batch_no=' + this.batches[index].batch_no).subscribe(response => {
      this.distributors = response;
    });
  }

  getInvoiceDetails(index) {
    index = index - 1;
    this.invoice_date = this.distributors[index].invoice_date;
    this.invoice_no = this.distributors[index].invoice_no;
  }

  addProduct() {
    this.tempList['p_name'] = this.p_name;
    this.tempList['batch_no'] = this.batch_no;
    this.tempList['distributor_name'] = this.distributor_name;
    this.tempList['t_qty'] = this.t_qty;
    this.tempList['exp_date'] = this.exp_date;
    this.tempList['invoice_date'] = this.invoice_date;
    this.tempList['invoice_no'] = this.invoice_no;
    this.tempList['return_qty'] = this.return_qty;
    this.tempList['reason'] = this.reason;
    this.returnProducts[this.index] = this.tempList;
    this.index++;

    this.tempList = {};
    this.p_name = '';
    this.batch_no = '';
    this.distributor_name = '';
    this.t_qty = 0;
    this.exp_date = '';
    this.invoice_date = '';
    this.invoice_no = '';
    this.return_qty = 0;
    this.selectComponent.open();
  }

  saveReturnForm() {
    this.service.post('return.php?type=saveDebitReturn', JSON.stringify(this.returnProducts))
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.returnProducts = [];
        this.index = 0;
        this.tempList = {};
        this.p_name = '';
        this.batch_no = '';
        this.distributor_name = '';
        this.t_qty = 0;
        this.exp_date = '';
        this.invoice_date = '';
        this.invoice_no = '';
        this.getDebitNotes();
        this.isSecond = false;
        this.isFirst = true;
      } else {
        alert(response['status']);
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  checkReturn(value) {
    if (value === 'Debit Note') {
      this.isFirst = true;
    } else {
      this.isFirst = false;
    }
  }

  filterTable(value) {
    this.debitNotes1 = [];
    let index = 0;
    const length = Object.keys(this.debitnotes).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.debitnotes[i].p_name + ' ' + this.debitnotes[i].batch_no + ' ' + this.debitnotes[i].distributor_name + ' ' + this.debitnotes[i].t_qty + ' ' + this.debitnotes[i].exp_date + ' ' + this.debitnotes[i].invoice_date + ' ' + this.debitnotes[i].invoice_no + ' ' + this.debitnotes[i].return_qty + ' ' + this.debitnotes[i].reason).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.debitNotes1[index] = this.debitnotes[i];
        index++;
      }
    }
  }

  showForm() {
    this.isFirst = false;
    this.isSecond = true;
  }

  closeForm() {
    this.isSecond = false;
    this.isFirst = true;
  }

  deletProd(index) {
    this.returnProducts.splice(index, 1);
    this.index--;
  }
}
