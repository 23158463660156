import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-activation',
  templateUrl: './card-activation.component.html',
  styleUrls: ['./card-activation.component.scss']
})
export class CardActivationComponent implements OnInit {
  card_no = '';
  isShow = false;
  email = '';
  constructor(private service: DataAccessService) { }

  ngOnInit() {
  }

  checkCardAvailablity() {
    this.service.get('aushadhmitra.php?type=checkCardAvailablity&card_no=' + this.card_no).subscribe(response => {
      if (response['status'] === 'success') {
        this.isShow = true;
      } else {
        this.isShow = false;
        this.card_no = '';
        alert('CARD is Not available or CARD number is already used!');
      }
    });
  }

  activateCard(data) {
    const temp = data.value;
    if (temp['customer_name'].length <= 0) {
      alert('Customer Name is Required');
      return;
    }
    if (temp['contact_no'].length <= 0) {
      alert('Contact No is Required');
      return;
    }
    if (temp['email_id'].length <= 0) {
      alert('Email Id is Required');
      return;
    }
    if (temp['gender'].length <= 0) {
      alert('Gender is Required');
      return;
    }
    if (temp['address'].length <= 0) {
      alert('Address is Required');
      return;
    }
    if (temp['card_no'].length <= 0) {
      alert('Card No is Required');
      return;
    }
    this.service.post('aushadhmitra.php?type=activateCard', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'success') {
        this.isShow = false;
        this.card_no = '';
        data.reset();
        alert('Card has been Activated Successfully.');
      }
    });
  }

  validateEmail(emailField) {
    this.email = emailField.toLowerCase();
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(emailField) === false) {
        alert('Invalid Email Address');
        const element1 = document.getElementById('email') as HTMLElement;
        element1.focus();
    }
    return true;
  }

  checkValidation(value) {
    if ((value.toString().length < 10) || value.toString().length > 11) {
      alert('Invalid Contact Number. Please enter valid contact number!');
      const element1 = document.getElementById('contact') as HTMLElement;
      element1.focus();
    }
  }

}
