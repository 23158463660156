import { HttpClientModule } from '@angular/common/http';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { DataAccessService } from './../../data-access.service';
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { IOption as ngSelectOption, SelectComponent } from 'ng-select';
declare var $: any;


@Component({
  selector: 'app-wsales',
  templateUrl: './wsales.component.html',
  styleUrls: ['./wsales.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WsalesComponent implements OnInit {

  @ViewChild('prod') selectComponent: SelectComponent;
  @ViewChild('customer') selectComponent1: SelectComponent;
  @ViewChild('doctors1') selectComponent2: SelectComponent;

  @Output() patientNameChange = new EventEmitter<string>(); // Ev

  aushadhmitra_card_no = '';
  isSaveSales = 0;
  isFirst = false;
  finalResult;
  order_no;

  p_name;
  pack_size = '';
  batch_no;
  qty = 0;
  disc = 0;
  mrp = 0;
  pack_mrp = 0;
  shelf;
  p_cat;
  gst = 0;
  hsn = '';
  m_name = '';
  unit_net_rate = 0;
  expiry_date = '';
  templist = {};
  batch_qty = 0;
  total_qty = 0;

  profit_per = 0;
  profit_amt = 0;
  max_disc_per = 0;

  taxable_total: number;
  disc_total: number;
  tax_total: number;
  net_total = '';
  profit_total: number;
  payble_amt: number;
  isCard = false;

  products;
  products1;
  patients;
  doctors;
  banks;
  productList = [];
  index = 0;
  patient_no = '';
  order_date;
  cash_credit = 'Cash';
  isCounter = false;
  patient_from = 'Regular';
  patient_email;
  patient_contact;
  patient_address;
  disc_per = 0;
  reminder_days = 0;
  patient_name = '';
  doctor_name = '';
  selectedFile: File;
  isUploadPrescription = 0;

  hsns;
  purchaseProd;
  purchaseProd1;

  purchaseMRP = 0;
  purchasePack_size = '';
  purchaseP_cat = '';
  purchaseGeneric = '';
  purchaseP_no = '';
  send_email = 'Yes';

  role;
  isSalesman = false;
  isAdmin = false;
  isPurchase = false;
  isMember = true;
  iscardtrue = false;
  card_no = '';
  total_points = 0;
  redeem_points = 0;
  discount_categories;
  balance_points = 0;
  near_expiry = 0;
  redeem_amt = '0';
  total_payble = '0';
  total_redeem = 0;
  earned_points = 0;
  isAushadhmitraCard = false;
  // tslint:disable-next-line:max-line-length
  constructor(private service: DataAccessService, private _hotkeysService: HotkeysService, private router: Router, private http: HttpClientModule) {
    this.taxable_total = 0.00;
    this.disc_total = 0.00;
    this.tax_total = 0.00;
    this.net_total = '0.00';
    this.profit_total = 0.00;
    this.payble_amt = 0.00;
    this.getTodaysDate();

    this.role = localStorage.getItem('role');
    if (this.role === 'Admin') {
      this.isAdmin = true;
      this.isSalesman = false;
      this.isPurchase = false;
    } else if (this.role === 'Salesman') {
      this.isSalesman = true;
      this.isPurchase = false;
      this.isAdmin = false;
    } else if (this.role === 'Purchase') {
      this.isPurchase = true;
      this.isSalesman = false;
      this.isAdmin = false;
      this.router.navigate(['/dashboard']);
    }

    this._hotkeysService.add(new Hotkey('ctrl+d', (event: KeyboardEvent): boolean => {
      const element1 = document.getElementById('bill_date') as HTMLElement;
      element1.focus();
      return false;
    }, ['INPUT', 'SELECT', 'TEXTAREA']));
   }


   state = '';



  ngOnInit() {

    this.state = localStorage.getItem('state');
    this.getAvailableProducts();
    this.getDetails();
    this.getDiscountCategories();
    this.getGenericDetails();
    this.getUserData();
    this.getSchemeMaster();
  }

  getAvailableProducts() {
    this.service.get('sales.php?type=getWAvailableProducts').subscribe(response => {
      this.products1 = response;
      this.products = response;
      // this.getAvailableProducts1();
    });
  }


  getSchemeMaster() {
    this.service.get('master.php?type=getschemeMaster')
    .subscribe(response => {
      this.schemeList = response;
    });
  }
  schemeList;
  getProductOfGen(selectedGeneric: string) {
   
    this.products1 = this.products.filter(product => product.generic == selectedGeneric);
    console.log(this.products1);
  }

  clearFilter(){
    this.products1 = this.products ;
  }
 

 updatePatientName() {
  
  this.patientNameChange.emit(this.patient_name); // Emit the new patient name
}

   
 

  getDetails() {
    this.service.get('sales.php?type=getInitDetails')
    .subscribe(response => {
      const data = response;
      this.patients = data['patients'];
      this.doctors = data['doctors'];
      this.banks = data['banks'];
      this.selectComponent.open();
    });
  }

  getDiscountCategories() {
    this.service.get('sales.php?type=getDiscountCategories').subscribe(response => {
      this.discount_categories = response;
    });
  }

  getGenericDet(event){
    console.log(event);

  }


  getGenericDetails() {
    this.service.get('master.php?type=getGenericDetailsForSub')
    .subscribe(response => {
      this.generics = response;
    });
  }
  generics;


  


  newGeneric(){

  }


  creditDays = 0;
  cashDiscount = 0;


  getUserData() {
    this.service.get('sales.php?type=getCustomerDataForWholesale').subscribe(response => {
      this.searchCustData = response;
    });
  }

  sch_per =0;
  Custstate ='';

  selectCustomer(data){
    console.log(data);
    this.patient_address = data['c_add'];
    this.patient_email = data['c_mail'];
    this.patient_name = data['c_name'];
    this.patient_contact = data['c_cont'];
    this.creditDays = data['creditDays'];
    this.cashDiscount = data['cashDiscount'];
    this.Custstate = data['state'];
    

    console.log(this.sch_per);
   

    this.patientNameChange.emit(this.patient_name); 
   }

  searchCustData;
  mrp1=0;
  schemeMaster=[];

  freeQty = 0;

  scheme ;
  netRate = 0;
  bilineWith = 'Sale Rate';
  totalQty = 0;

  CalculateFreeQty(){
    this.totalQty =0;

    if(this.bilineWith == 'Net Rate'){
 
      this.freeQty =  parseFloat(((this.qty * this.scheme) / 100  ).toFixed(2));
      this.netRate = parseFloat(((this.mrp2 * this.qty) / (this.qty + this.freeQty)  ).toFixed(2));
      this.totalQty = this.qty ;
 
      this.mrp = this.netRate;
      this.checkTryData();
      this.calculate();

  
    }else{
 
      this.freeQty =  parseFloat(((this.qty * this.scheme) / 100  ).toFixed(2));
      this.totalQty = this.qty + this.freeQty;
      this.netRate = this.mrp2;
      this.mrp = this.netRate;
      this.checkTryData();
      this.calculate();
    }


    if(this.scheme == 0){
      this.totalQty = this.qty ;
    }
    

  }
 
  mrp2 =0;
  getProductDetails(event) {
    this.total_qty = event['wAvailableQty'];
    this.batch_no = event['batch_no'];
    this.mrp1 = event['unit_mrp'];
    this.sch_per = event['sch_per'];
    this.p_cat = event['p_cat']; 
    this.shelf = event['shelf'];
    this.gst = event['gst_per'];
    this.batch_qty = event['t_qty'];
    this.pack_size = event['pack_size'];
    this.expiry_date = event['expiry'];
    this.unit_net_rate = event['rate'];
    this.pack_mrp = event['mrp'];
    this.hsn = event['hsn'];
    this.m_name = event['m_name'];
    this.schemeMaster = event['schemeMaster'];
    this.mrp =0;

    let gst_per = event['gst_per'];
    let retailMargin = event['retailMargin'];

    let mrp1 = event['mrp'];


    let subRate = 0;

    subRate = mrp1 - (mrp1 * (retailMargin / 100));

    let subgst = 0;

    subgst =  (1 + (gst_per / 100));

 
    this.mrp2 =  parseFloat((subRate / subgst).toFixed(2));
    this.mrp =  parseFloat((subRate / subgst).toFixed(2));

    // let profit_amt = ((this.mrp*1) - (this.unit_net_rate*1)) - ((1*this.mrp)*(this.disc/100));
    // let profit_per = profit_amt * 100 / (this.mrp*1);
    // if (this.p_cat == 'GENERIC') {
    //   this.max_disc_per = profit_per - 27;
    // } else if (this.p_cat == 'COSMATIC') {
    //   this.max_disc_per = profit_per - 10;
    // } else if (this.p_cat == 'AYURVEDIC') {
    //   this.max_disc_per = profit_per - 12;
    // } else if (this.p_cat == 'SURGICAL') {
    //   this.max_disc_per = profit_per - 14;
    // } else {
    //   this.max_disc_per = profit_per - 14;
    // }
    // this.max_disc_per = +parseFloat(this.max_disc_per + '').toFixed(2);

    this.checkTryData();
  }


  
  checkTryData(){

    let profit_amt = ((this.mrp*1) - (this.unit_net_rate*1)) - ((1*this.mrp)*(this.disc/100));
    let profit_per = profit_amt * 100 / (this.mrp*1);
    // if (this.p_cat == 'GENERIC') {
    //   this.max_disc_per = profit_per - 27;
    // } else if (this.p_cat == 'COSMATIC') {
    //   this.max_disc_per = profit_per - 10;
    // } else if (this.p_cat == 'AYURVEDIC') {
    //   this.max_disc_per = profit_per - 12;
    // } else if (this.p_cat == 'SURGICAL') {
    //   this.max_disc_per = profit_per - 14;
    // } else {
    //   this.max_disc_per = profit_per - 14;
    // }
    // this.max_disc_per = +parseFloat(this.max_disc_per + '').toFixed(2);


  }






  gstAmt = 0;

  taxable = 0;
  tax=0;
  discAmt = 0;

  igst = 0
  sgst=0;
  cgst=0;

  calculate() {

    this.profit = 0;
    //if (+this.disc <= +this.max_disc_per) {
      this.profit_amt = ((this.mrp*this.qty) - (this.unit_net_rate*this.qty)) - ((this.qty*this.mrp)*(this.disc/100));
      this.profit_per = this.profit_amt * 100 / (this.mrp*this.qty);
    // } else {
    //   this.disc = this.max_disc_per;
    //   this.calculate();
    // }

 

    this.taxable = parseFloat((this.mrp  * this.qty).toFixed(2));
     

    this.tax = parseFloat((this.taxable  * (this.gst / 100)).toFixed(2));
    this.discAmt = parseFloat((this.taxable  * (this.disc / 100)).toFixed(2));


    if(this.Custstate == this.state){
        
      this.igst =  0;
      this.sgst = this.tax / 2;
      this.cgst = this.tax / 2;

    }else{
 
      this.igst =  this.tax;
      this.sgst = 0;
      this.cgst = 0;

    }
     

    // this.sgst = (((this.mrp / ( 1 + (this.gst / 100))) * this.qty) * (this.gst / (100 * 2))).toFixed(2);
    // this.cgst = (((this.mrp / ( 1 + (this.gst / 100))) * this.qty) * (this.gst / (100 * 2))).toFixed(2);
    
    // this.net = ((this.qty * this.mrp) - (this.qty * this.mrp) * (this.disc / 100)).toFixed(2);


    this.profit = parseFloat((((this.mrp*this.qty) - (this.unit_net_rate*this.qty)) - this.discAmt ).toFixed(2));



  }


  profit = 0;

  checkCashCredit(value) {
    if (value === 'Card') {
      this.isCard = true;
    } else {
      this.isCard = false;
    }
  }

 
  addProduct() {
    if (this.p_name === '' || this.qty === 0) {
      return;
    }
    const length1 = Object.keys(this.products1).length;
    for (let j = 0; j < length1; j++) {
      if (this.products1[j].p_name === this.p_name) {
        this.products1[j].t_qty = +this.products1[j].t_qty - +this.qty;
        this.products1[j].label = this.products1[j].p_name + '(' + this.products1[j].t_qty + ')';
      }
    }
    if (this.qty > this.batch_qty) {
      const p_name = this.p_name;
      let qty = this.qty;
      const length = Object.keys(this.products).length;
      for (let i = 0; i < length; i++) {
        if (this.products[i].p_name === p_name) {
          if (qty > this.products[i].t_qty) {
            this.qty = this.products[i].t_qty;
            qty = qty - this.products[i].t_qty;
          } else if (qty > 0 && qty <= this.products[i].t_qty) {
            this.qty = qty;
            qty = 0;
          } else if (qty <= 0) {
            break;
          }
          this.p_name = p_name;
          this.batch_no = this.products[i].batch_no;
          this.mrp = this.products[i].unit_mrp;
          this.pack_mrp = this.products[i].mrp;
          this.shelf = this.products[i].shelf;
          this.p_cat = this.products[i].p_cat;
          this.pack_size = this.products[i].pack_size;
          this.gst = this.products[i].gst_per;
          this.expiry_date = this.products[i].expiry;
          this.hsn = this.products[i].hsn;
          this.m_name = this.products[i].m_name;
          this.addToList();
        }
      }
    } else {
      this.addToList();
    }
  }

  getCategory(value) {
    if (value === 'SCHEDULE H') {
      return '#85C81A';
    } else if (value === 'SCHEDULE H1') {
      return '#F8B7B6';
    }
  }

  saveSales(data) {
    if (this.isSaveSales !== 0) {
      alert('Please Wait...');
      return;
    }
    if (this.index === 0) {
      this.isSaveSales = 0;
      alert('At least 1 product is required in Sale');
      return;
    }
    if (this.patient_from === 'Regular' && this.patient_name === '') {
      alert('Patient Name is Required');
      return;
    }
    /* if (this.patient_from === 'Regular' && this.doctor_name === '') {
      alert('Doctor Name is Required');
      return;
    } */
    this.isSaveSales = 1;
    const uploadData = new FormData();
    const temp = data.value;
    if (this.isUploadPrescription === 1) {
      uploadData.append('prescription', this.selectedFile, this.selectedFile.name);
    }
    uploadData.append('patient_no', this.patient_no);
    uploadData.append('products', JSON.stringify(this.productList));
    uploadData.append('taxable', this.taxable_total + '');
    uploadData.append('discount', this.disc_total + '');
    uploadData.append('gst', this.tax_total + '');
    uploadData.append('net', this.net_total + '');
    uploadData.append('profit', this.profit_total + '');
    uploadData.append('patient_email', this.patient_email);
    uploadData.append('patient_contact', this.patient_contact);
    uploadData.append('patient_address', this.patient_address);
    uploadData.append('cash_credit', temp.cash_credit);
    uploadData.append('doctor_name', temp.doctor_name);
    uploadData.append('order_date', this.order_date);
    uploadData.append('patient_from', temp.patient_from);
    uploadData.append('patient_name', this.patient_name);
    uploadData.append('reminder_days', temp.reminder_days);
    uploadData.append('send_email', temp.send_email);
    uploadData.append('aushadhmitra_card_no', this.aushadhmitra_card_no);
    uploadData.append('earned_points', this.total_points * 5 + '');
    uploadData.append('redeem_points', this.redeem_points + '');
    uploadData.append('total_payble', this.total_payble + '');
    uploadData.append('disc_per', this.disc + '');

    temp['patient_no'] = this.patient_no;
    temp['products'] = this.productList;
    temp['taxable'] = this.taxable_total;
    temp['discount'] = this.disc_total;
    temp['gst'] = this.tax_total;
    temp['net'] = this.net_total;
    temp['profit'] = this.profit_total;
    temp['patient_email'] = this.patient_email;
    temp['patient_contact'] = this.patient_contact;
    temp['patient_address'] = this.patient_address;
    this.finalResult = temp;
    this.service.post('sales.php?type=WholeSalesaveSales&email=' + localStorage.getItem('email') + '&entry_by='+localStorage.getItem('cl_saler_name'), uploadData).subscribe(response => {
      if (response['status'] === 'success') {
        this.isSaveSales = 0;
        alert('Sale has been saved.');
        this.patient_from = 'Regular';
        this.patient_no = '';
        this.patient_name = '';
        this.doctor_name = '';
        this.patient_contact = '';
        this.patient_email = '';
        this.patient_address = '';
        this.cash_credit = 'Cash';
        this.disc_per = 0;
        this.reminder_days = 0;
        this.getTodaysDate();
        this.taxable_total = 0.00;
        this.disc_total = 0.00;
        this.tax_total = 0.00;
        this.net_total = '0.00';
        this.profit_total = 0.00;
        this.redeem_amt = '0';
        this.total_payble = '0';
        this.balance_points = 0;
        this.redeem_points = 0;
        this.near_expiry = 0;
        this.productList = [];
        this.templist = {};
        this.index = 0;
        this.total_points = 0;
        this.aushadhmitra_card_no = '';
        this.isAushadhmitraCard = false;
        this.balance_points = 0;
        this.redeem_points = 0;
        this.near_expiry = 0;
        this.net_total = '0';
        this.redeem_amt = '';
        this.order_no = response['order_no'];
        this.finalResult['order_no'] = this.order_no;
        this.isFirst = true;
        const element1 = document.getElementById('printbtn') as HTMLElement;
        element1.focus();
      } else {
        alert(response['status']);
      }
      },
      (error: Response) => {
        if (error.status === 400) {
          alert('An error has occurred.');
        } else {
          alert('An error has occurred, http status:' + error.status);
        }
      });
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0];
    this.isUploadPrescription = 1;
  }

  getPatientNo(event) {
    const length = Object.keys(this.patients).length;
    for (let i = 0; i < length; i++) {
      if (this.patients[i].c_name === event.c_name) {
        this.patient_no = this.patients[i].c_no;
        this.patient_email = this.patients[i].c_mail;
        this.patient_contact = this.patients[i].c_cont;
        this.patient_address = this.patients[i].c_add;
        this.patient_name = this.patients[i].c_name;
      }
    }
  }

  showCustomerModal(value) {
    const ans = confirm('Add Patient into Master List ?');
    if (ans === true) {
      $('#exampleModal').modal('show');
    }
  }

  showDoctorModal(value) {
    const ans = confirm('Add Doctor into Master List ?');
    if (ans === true) {
      $('#doctorModal').modal('show');
    }
  }

  getTodaysDate() {
    const today = new Date();
    this.order_date = today.toISOString().substr(0, 10);
  }

  checkPatientFrom(value) {
    if (value === 'Regular') {
      this.isCounter = false;
    } else {
      this.isCounter = true;
    }
  }

  close() {
    delete this.finalResult;
    this.isFirst = false;
    this.getDetails();
  }

  print() {
    // tslint:disable-next-line:max-line-length
    window.open(this.service.url1 + 'sales_print.php?order_no=' + this.order_no + '&id=' + localStorage.getItem('id') + '&user=' + localStorage.getItem('cl_saler_name'), '_blank');
    this.close();
    this.isFirst = false;
    this.getDetails();
  }

  checkQty() {
    if (this.total_qty < this.qty) {
      alert('Product Total Qty is ' + this.total_qty);
      this.qty = this.total_qty;
      const element1 = document.getElementById('qty') as HTMLElement;
      element1.focus();
    }
  }

  addToList() {

 
 

    let a_disc = 0;
    this.selectComponent.open();
    this.templist['p_name'] = this.p_name;
    this.templist['batch_no'] = this.batch_no;
    this.templist['qty'] = this.qty;
    this.templist['disc'] = this.disc;
    this.templist['mrp'] = this.pack_mrp;
    this.templist['shelf'] = this.shelf;
    this.templist['p_cat'] = this.p_cat;
    this.templist['pack_size'] = this.pack_size;
    this.templist['gst'] = this.gst;
    this.templist['expiry_date'] = this.expiry_date;
    this.templist['hsn'] = this.hsn;
    this.templist['m_name'] = this.m_name;
    this.templist['schemFomat'] = this.schemFomat;
    this.templist['taxable'] = parseFloat((this.mrp  * this.qty).toFixed(2));
    this.templist['disc_amt'] = parseFloat((this.taxable  * (this.disc / 100)).toFixed(2));
    this.templist['tax'] = parseFloat((this.taxable  * (this.gst / 100)).toFixed(2));
    this.templist['rate'] = this.mrp;
    let tax = parseFloat((this.taxable  * (this.gst / 100)).toFixed(2));

    let discAmt = parseFloat((this.taxable  * (this.disc / 100)).toFixed(2));

    
    if(this.Custstate == this.state){
        
      this.templist['igst'] =  0;
      this.templist['sgst'] = tax / 2;
      this.templist['cgst'] = tax / 2;

    }else{
 
      this.templist['igst'] =  tax;
      this.templist['sgst'] = 0;
      this.templist['cgst'] = 0;

    }

    parseFloat((((this.mrp * this.qty) - (this.unit_net_rate * this.qty)) - discAmt).toFixed(2));
 
     // tslint:disable-next-line:max-line-length
    this.templist['net'] = parseFloat((((this.qty * this.mrp)   - discAmt ) + tax).toFixed(2));
    // tslint:disable-next-line:max-line-length
    this.templist['profit'] =  parseFloat((((this.mrp * this.qty) - (this.unit_net_rate * this.qty)) - discAmt).toFixed(2));
    const len = Object.keys(this.discount_categories).length;
    for (let i = 0; i < len; i++) {
      if (this.discount_categories[i].cat_name === this.p_cat) {
        a_disc = Math.round((this.templist['net'] * this.discount_categories[i].discount) / 100);
        // a_disc = (this.templist['net'] * this.discount_categories[i].discount) / 100;
        this.templist['a_disc'] = +(a_disc).toFixed(2) * 5;
        this.total_points += +(a_disc.toFixed(2));

        let a_redeem = Math.round((this.templist['net'] * this.discount_categories[i].redeem) / 100);
        this.templist['a_redeem'] = Math.ceil((a_redeem / 0.75) * 5);
        this.total_redeem += Math.ceil(a_redeem / 0.75);
        // let a_redeem = (this.templist['net'] * this.discount_categories[i].redeem) / 100;
        // this.templist['a_redeem'] = (a_redeem / 0.75) * 5;
        // this.total_redeem += a_redeem / 0.75;
      }
    }
    this.productList[this.index] = this.templist;
    this.index++;
    this.taxable_total = parseFloat((this.taxable_total + +this.templist['taxable']).toFixed(2));
    this.disc_total = parseFloat((this.disc_total + +this.templist['disc_amt']).toFixed(2));
    this.tax_total = parseFloat((this.tax_total + +this.templist['tax']).toFixed(2));
    this.profit_total = parseFloat((this.profit_total + +this.templist['profit']).toFixed(2));
    this.net_total = (+this.taxable_total - +this.disc_total + +this.tax_total).toFixed(2);
    this.total_payble = (+this.net_total - (this.redeem_points / 5)).toFixed(0);
    this.calculateRedeemPoints();
    this.p_name = '';
    this.batch_no = '';
    this.qty = 0;
    this.disc = 0;
    this.pack_mrp = 0;
    this.mrp = 0;
    this.shelf = '';
    this.p_cat = '';
    this.gst = 0;
    this.unit_net_rate = 0;
    
    this.scheme = 0;
    this.freeQty = 0;
    this.netRate = 0;
     this.discAmt = 0;
    this.taxable = 0;
    this.tax = 0;
    this.igst = 0;
    this.cgst = 0;
    this.totalQty = 0;
    this.sgst = 0;
 
    this.pack_size = '';
    this.expiry_date = '';
    this.hsn = '';
    this.templist = {};
    this.schemFomat = '';
  }

  deleteProduct(index) {
    const length1 = Object.keys(this.products1).length;
    for (let j = 0; j < length1; j++) {
      if (this.products1[j].p_name === this.productList[index].p_name) {
        this.products1[j].t_qty = +this.products1[j].t_qty + +this.productList[index].qty;
        this.products1[j].label = this.products1[j].p_name + '(' + this.products1[j].t_qty + ')';
      }
    }
    this.taxable_total = parseFloat((this.taxable_total - +this.productList[index].taxable).toFixed(2));
    this.disc_total = parseFloat((this.disc_total - +this.productList[index].disc_amt).toFixed(2));
    this.tax_total = parseFloat((this.tax_total - +this.productList[index].tax).toFixed(2));
    this.net_total = (+this.taxable_total - +this.disc_total + +this.tax_total).toFixed(2);
    this.total_points = Math.ceil(this.total_points - (this.productList[index].a_disc / 5));
    this.total_redeem = Math.ceil(this.total_redeem - (this.productList[index].a_redeem) / 5);
    this.profit_total = parseFloat((this.profit_total - this.productList[index].profit).toFixed(2));
    this.productList.splice(index, 1);
    this.index--;
    this.calculateRedeemPoints();
  }

  DiscountOnTotal(value) {
    this.payble_amt = parseFloat((+this.net_total - +((+this.net_total * value) / 100)).toFixed(2));
  }

  saveCustomer(customerData) {
    this.service.post('master.php?type=saveCustomer', JSON.stringify(customerData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        $('#exampleModal').modal('hide');
        this.service.get('sales.php?type=getInitDetails')
        .subscribe(response1 => {
          const data = response1;
          this.patients = data['patients'];
          this.selectComponent1.open();
        });
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }


  
  schemFomat= 'NA';

  AnotherFunction(index){
  

    console.log(this.schemFomat);
    if(this.scheme == 0){
      this.schemFomat = 'NA';
    }else{
 
      let quantity =  this.schemeMaster[index-1].quantity;
      let freeQty =  this.schemeMaster[index-1].freeQty;
 
      this.schemFomat = quantity+' + '+freeQty;
    }
    console.log(this.schemFomat);
  }




  saveDoctor(doctorData) {
    this.service.post('master.php?type=saveDoctor', JSON.stringify(doctorData.value))
    .subscribe(response => {
      if (response['status'] === 'success') {
        $('#doctorModal').modal('hide');
        this.service.get('sales.php?type=getInitDetails')
        .subscribe(response1 => {
          const data = response1;
          this.doctors = data['doctors'];
          this.selectComponent2.open();
        });
      }
      },
    (error: Response) => {
      if (error.status === 400) {
        alert('An error has occurred.');
      } else {
        alert('An error has occurred, http status:' + error.status);
      }
    });
  }

  noProductFound(event) {
    const val = event;
    if (val.indexOf('$') >= -1) {
      const res = val.split('$');
      const length = Object.keys(this.products).length;
      for (let i = 0; i < length; i++) {
        if (this.products[i].p_name === res[0]) {
          this.batch_no = res[1];
          this.p_name = this.products[i].p_name;
          this.mrp = this.products[i].unit_mrp;
          this.p_cat = this.products[i].p_cat;
          this.shelf = this.products[i].shelf;
          this.gst = this.products[i].gst_per;
          this.batch_qty = this.products[i].t_qty;
          this.pack_size = this.products[i].pack_size;
          this.expiry_date = this.products[i].expiry;
          this.unit_net_rate = this.products[i].unit_net_rate;
          this.pack_mrp = this.products[i].mrp;
          this.hsn = this.products[i].hsn;
          const element1 = document.getElementById('qty') as HTMLElement;
          element1.focus();
          break;
        }
      }

      const length1 = Object.keys(this.products1).length;
      for (let i = 0; i < length1; i++) {
        if (this.products1[i].p_name === this.p_name) {
          this.total_qty = this.products1[i].t_qty;
          break;
        }
      }
    } else {
      const ans = confirm('Product Not Found! Add New Purchase ?');
      if (ans === true) {
        this.showPurchaseModal();
      }
    }
  }

  showPurchaseModal() {
    this.service.get('purchase.php?type=getInitDetails').subscribe(response => {
      const data = response;
      this.hsns = data['hsns'];
      this.purchaseProd = data['products'];
      this.purchaseProd1 = this.products;
    });
    $('#purchaseModal').modal('show');
  }

  savePurchase(data) {
    const temp = data.value;
    temp['pack_size'] = this.purchasePack_size;
    temp['p_no'] = this.purchaseP_no;
    temp['generic'] = this.purchaseGeneric;
    temp['p_cat'] = this.purchaseP_cat;
    temp['scheme'] = 0;
    temp['disc'] = 0;
    temp['shelf'] = '';
    //temp['gst'] = this.tax_total;
    temp['taxable'] = +temp['qty'] * +temp['purchase_rate'];
    temp['sgst'] = parseFloat((+temp['taxable'] * (temp['gst'] / (100 * 2))).toFixed(2));
    temp['cgst'] = parseFloat((+temp['taxable'] * (temp['gst'] / (100 * 2))).toFixed(2));
    temp['igst'] = 0;



    // if(this.Custstate == this.state){
        
    //   temp['igst'] =  0;
    //   temp['sgst'] = tax / 2;
    //   temp['cgst'] = tax / 2;

    // }else{
 
    //   temp['igst'] =  tax;
    //   temp['sgst'] = 0;
    //   temp['cgst'] = 0;

    // }





    // tslint:disable-next-line:max-line-length
    temp['net_rate'] = parseFloat(((((+temp['purchase_rate'] + ((+temp['purchase_rate'] * +temp['gst']) / 100)) * temp['qty']) / (temp['qty'] + 0)) - ((temp['purchase_rate'] * 0) / 100)).toFixed(2));

    const temp1 = {};
    const temp2 = {};
    temp2[0] = temp;
    temp1['products'] = temp2;
    temp1['gross_total'] = temp['taxable'];
    temp1['disc_total'] = 0;
    temp1['tax_total'] = +temp['sgst'] + +temp['cgst'];
    temp1['net_total'] = +temp['taxable'] + +temp1['tax_total'];
    temp1['gst_type'] = 'GST';
    temp1['invoice_no'] = '';
    temp1['cash_disc'] = 'Cash';
    const today = new Date();
    temp1['invoice_date'] = today.toISOString().substr(0, 10);
    temp1['distributor_name'] = 'Dumy Distributor';

    console.log(temp1);

    // this.service.post('purchase.php?type=savePurchase', JSON.stringify(temp1))
    // .subscribe(response => {
    //   if (response['status'] === 'success') {
    //     this.service.get('sales.php?type=getInitDetails')
    //     .subscribe(response1 => {
    //       const data1 = response1;
    //       this.products = data1['products'];
    //       this.products1 = data1['products1'];
    //       this.selectComponent.open();
    //     });
    //     $('#purchaseModal').modal('hide');
    //     data.reset();
    //   } else {
    //     alert(response['status']);
    //   }
    //   },
    // (error: Response) => {
    //   if (error.status === 400) {
    //     alert('An error has occurred.');
    //   } else {
    //     alert('An error has occurred, http status:' + error.status);
    //   }
    // });
  }

  getPurchaseProdMRP(event) {
    this.purchaseMRP = +event.mrp;
    this.purchasePack_size = event.pack_size;
    this.purchaseP_cat = event.p_cat;
    this.purchaseGeneric = event.generic;
    this.purchaseP_no = event.p_no;
  }

  checkValidation(value, value1) {
    if (value1 === 'contact') {
       if ((value.toString().length < 10) || value.toString().length > 12) {
         alert('Invalid Contact Number. Please enter valid contact number!');
         return;
       }
     } else if (value1 === 'email') {
       const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
         if (reg.test(value) === false) {
             alert('Invalid Email Address');
             return false;
         }
         return true;
     }
   }

   checkEmail(value) {
     this.patient_email = value.toLowerCase();
   }

   showMember() {
    $('#memberModel').modal('show');
   }

   getPointDetails(value) {
     value = value.replace(/\s/g, '');
     this.aushadhmitra_card_no = value;
     const len = value.length;
     if (len >= 6) {
      this.service.get('aushadhmitra.php?type=getPointDetails&card_no=' + value).subscribe(response => {
        if (response['status'] === 'success') {
          this.earned_points = (response['earned_points']).toFixed(2);
          this.balance_points = (response['earned_points']).toFixed(2);
          this.near_expiry = (response['near_expiry_points']).toFixed(2);
          this.isAushadhmitraCard = true;
          this.patient_name = response['customer_name'];
          this.patient_contact = response['contact_no'];
          this.patient_email = response['email'];
        } else {
          this.aushadhmitra_card_no = '';
          this.isAushadhmitraCard = false;
        }
        this.calculateRedeemPoints();
      });
     }
   }

   calculateRedeemPoints() {
     let redeem_points = this.total_redeem * 5;
     this.balance_points = this.earned_points;
    if (this.balance_points >= redeem_points) {
      this.redeem_points = redeem_points;
      this.balance_points = this.balance_points - redeem_points;
    } else {
      this.redeem_points = this.balance_points;
      this.balance_points = 0;
    }
    this.redeem_amt = ((this.redeem_points * 0.75) / 5).toFixed(2);
    this.total_payble = (+this.net_total - +this.redeem_amt).toFixed(0);
    this.net_total = (+this.net_total).toFixed(2);
    this.total_payble = (+this.total_payble).toFixed(2);
   }

}
