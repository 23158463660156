import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss']
})
export class CardDetailsComponent implements OnInit {
  cardDetails;
  card_no = '';
  isShow = false;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
  }

  getCardDetails() {
    this.service.get('aushadhmitra.php?type=getCardDetails&card_no=' + this.card_no).subscribe(response => {
      this.cardDetails = response;
      this.isShow = true;
      if (response === '[]') {
        this.isShow = false;
        this.card_no = '';
        alert('Invalid Card No');
      }
    });
  }

}
