import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {
  order = [];
  p_name = '';
  products;
  index = 0;
  pack_size = '';
  mrp = 0;
  ptr = 0;
  sch_qty = 0;
  sch_f_qty = 0;
  sch_per = 0;
  free_qty = 0;
  required_qty = '';
  previousOrders = [];
  isShow = false;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getProducts();
    this.getPreviousOrders();
  }

  getPreviousOrders() {
    this.service.get('wholesale.php?type=getPreviousOrders').subscribe((response:any) => {
      this.previousOrders = response;
    });
  }

  getProducts() {
    this.service.get('wholesale.php?type=getProducts').subscribe(response => {
      this.products = response;
    });
  }

  addList(data) {
    let temp = data.value;
    temp['client_no'] = 'WS-00001';
    temp['customer_no'] = localStorage.getItem('id');
    this.order[this.index] = data.value;
    this.index = this.index + 1;
    this.pack_size = '';
    this.mrp = 0;
    this.free_qty = 0;
    this.ptr = 0;
    this.sch_qty = 0;
    this.sch_f_qty = 0;
    this.sch_per = 0;
    this.p_name = '';
    this.required_qty = '';
    data.reset();
    const element1 = document.getElementById('p_name') as HTMLElement;
    element1.focus();
  }

  getProdDetails(value) {
    let len = Object.keys(this.products).length;
    for(let i = 0; i < len; i++) {
      let temp = this.products[i];
      if (temp['p_name'] === value) {
        this.pack_size = temp['pack_size'];
        this.mrp = temp['mrp'];
        this.ptr = temp['sale_rate'];
        this.sch_qty = temp['sch_qty'];
        this.sch_f_qty = temp['sch_f_qty'];
        this.sch_per = temp['sch_per'];
      }
    }
  }

  checkqty(value) {
    this.free_qty = Math.floor((value * this.sch_per) / 100);
  }

  saveOrder() {
    this.service.post('wholesale.php?type=saveOrder', JSON.stringify(this.order)).subscribe(response => {
      if (response === 'success') {
        this.order = [];
        this.index = 0;
      }
    });
  }
}
