import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
declare var $: any;

@Component({
  selector: 'app-po-report-customise',
  templateUrl: './po-report-customise.component.html',
  styleUrls: ['./po-report-customise.component.scss']
})
export class PoReportCustomiseComponent implements OnInit {
  sales;
  sales1 = [];
  from_date = '';
  to_date = '';
  customer_name = '';
  doctor_name = '';
  selectedOrder;
  isFirst = false;
  poNo;
  poProducts;
  getPurNo;
  getdistName;
  getdistMail;
  replyMsg;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getPOCustomise();
  }

  getPOCustomise() {
    this.service.get('master.php?type=getPOCustomise').subscribe(data => {
      this.sales = data;
      this.sales1 = this.sales;
    });
  }

  viewOrder(index, value) {
    this.selectedOrder = this.sales1[index];
    this.poNo = this.sales1[index].po_no;
    this.poProducts = this.selectedOrder['products'];
    if (value === 'view') {
      this.isFirst = true;
    } else if (value === 'pdf') {
      const po_no = this.selectedOrder['po_no'];
      const dist_name = this.selectedOrder['d_name'];
      // tslint:disable-next-line:max-line-length
        window.open(this.service.url1 + '/po_receipt.php?type=orderPDF&po_no=' + po_no + '&dist_name=' + dist_name + '&id=' + localStorage.getItem('id'),  '_blank');
      } else if (value === 'print') {
      const po_no = this.selectedOrder['po_no'];
      const dist_name = this.selectedOrder['d_name'];
      // tslint:disable-next-line:max-line-length
      window.open(this.service.url1 + '/poCust_print.php?po_no=' + po_no + '&dist_name=' + dist_name + '&id=' + localStorage.getItem('id'), '_blank');
    }
  }
  sendPoMail(mailid) {
    // tslint:disable-next-line:max-line-length
    this.service.get('po_receipt_mail.php?type=orderPDF&getPurNo=' + this.getPurNo + '&getdistName=' + this.getdistName + '&mailid=' + mailid)
    .subscribe(response => {
      if (response['status'] === 'success') {
        this.replyMsg = 'Mail Sent';
        alert('Mail sent successfully.');
        $('#exampleModal').modal('hide');
      } else {
        this.replyMsg = 'Mail Not Sent';
      }
    });

  }
  getModel(index) {
    this.selectedOrder = this.sales[index];
    this.getPurNo = this.sales[index].po_no;
    this.getdistName = this.sales[index].distributor_name;
    this.getdistMail =  this.sales[index].d_mail;
  }
  filterResult() {
    this.sales1 = [];
    let index = 0;
      const length = Object.keys(this.sales).length;
      if (this.from_date !== '' && this.to_date === '' && this.customer_name === '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          if (this.sales[i].order_date >= this.from_date) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          if (this.sales[i].order_date <= this.to_date) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date === '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date === '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          if (this.sales[i].order_date >= this.from_date && this.sales[i].order_date <= this.to_date) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date === '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (this.sales[i].order_date >= this.from_date && test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date === '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (this.sales[i].order_date >= this.from_date && test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date !== '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (this.sales[i].order_date <= this.to_date && test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (this.sales[i].order_date <= this.to_date && test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date === '' && this.customer_name !== '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          const test1 = this.sales[i].doctor_name;
          if (test1.includes(this.customer_name) && test1.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date !== '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (this.sales[i].order_date >= this.from_date && this.sales[i].order_date <= this.to_date && test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (this.sales[i].order_date >= this.from_date && this.sales[i].order_date <= this.to_date && test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else {
        this.sales1 = this.sales;
      }
  }
}
