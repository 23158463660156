import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-merchant-stores',
  templateUrl: './merchant-stores.component.html',
  styleUrls: ['./merchant-stores.component.scss']
})
export class MerchantStoresComponent implements OnInit {
  merchants;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getMerchants();
  }

  getMerchants() {
    this.service.get('getdata.php?type=getMerchants').subscribe(response => {
      this.merchants = response;
    });
  }

}
