import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upload-product',
  templateUrl: './upload-product.component.html',
  styleUrls: ['./upload-product.component.scss']
})
export class UploadProductComponent implements OnInit {
  purchaseProducts;
  products;
  availableProducts;

  p_cat = '';
  m_name = '';
  mrp = 0;
  rate = 0;
  subcategory = '';
  from_date;
  to_date;
  constructor(private service: DataAccessService) {
    let today = new Date();
    this.from_date = today.toISOString().substr(0, 10);
    today.setDate(today.getDate() + 7);
    this.to_date = today.toISOString().substr(0, 10);
  }

  ngOnInit() {
    this.getProducts();
    this.getPurchaseProducts();
    this.getAvailableProducts();
  }

  getPurchaseProducts() {
    this.service.get('aushadhmitra.php?type=getPurchaseProducts').subscribe(response => {
      this.purchaseProducts = response;
    });
  }

  getProducts() {
    this.service.get('aushadhmitra.php?type=getOnlineProducts').subscribe(response => {
      this.products = response;
    });
  }

  getAvailableProducts() {
    this.service.get('aushadhmitra.php?type=getAvailableProducts').subscribe(response => {
      this.availableProducts = response;
    });
  }

  getSelectedProduct(event) {
    this.p_cat = event.p_cat;
    this.m_name = event.m_name;
    this.mrp = event.mrp;
    this.rate = event.rate;
  }

  saveProduct(data) {
    this.service.post('aushadhmitra.php?type=saveProduct', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'success') {
        data.reset();
        this.getProducts();
      }
    });
  }

}
