import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { SelectModule } from 'ng-select';
import { HotkeyModule } from 'angular2-hotkeys';
import { DataAccessService } from './data-access.service';
import { HttpClientModule } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { ModalModule } from 'ngx-bootstrap/modal';
const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { AccountComponent } from './account/account.component';
import { AdjustGSTComponent } from './account/adjust-gst/adjust-gst.component';
import { AdjustPurchaseGSTComponent } from './account/adjust-purchase-gst/adjust-purchase-gst.component';
import { AdjustSalesGSTComponent } from './account/adjust-sales-gst/adjust-sales-gst.component';
import { AssetsMasterComponent } from './account/assets-master/assets-master.component';
import { ExpenseComponent } from './account/expense/expense.component';
import { InvestmentMasterComponent } from './account/investment-master/investment-master.component';
import { LedgerComponent } from './account/ledger/ledger.component';
import { LoanMasterComponent } from './account/loan-master/loan-master.component';
import { CardActivationComponent } from './aushadhmitra/card-activation/card-activation.component';
import { CardDetailsComponent } from './aushadhmitra/card-details/card-details.component';
import { HomePageComponent } from './aushadhmitra/home-page/home-page.component';
import { MedicalStoresComponent } from './aushadhmitra/medical-stores/medical-stores.component';
import { MerchantStoresComponent } from './aushadhmitra/merchant-stores/merchant-stores.component';
import { PurchaseOrderComponent } from './aushadhmitra/purchase-order/purchase-order.component';
import { ReceivedOrdersComponent } from './aushadhmitra/received-orders/received-orders.component';
import { UploadProductComponent } from './aushadhmitra/upload-product/upload-product.component';
import { CustomerPaymentComponent } from './customer-payment/customer-payment.component';
import { DailyClosingComponent } from './daily-closing/daily-closing.component';
import { DebitNoteComponent } from './debit-note/debit-note.component';
import { DistributorPaymentComponent } from './distributor-payment/distributor-payment.component';
import { GeneralPurchaseComponent } from './forms/general-purchase/general-purchase.component';
import { GeneralSalesComponent } from './forms/general-sales/general-sales.component';
import { GeneralComponent } from './forms/general/general.component';
import { GenerateBarcodeComponent } from './forms/generate-barcode/generate-barcode.component';
import { OpeningStockComponent } from './forms/opening-stock/opening-stock.component';
import { PaymentsComponent } from './forms/payments/payments.component';
import { PurchaseFormComponent } from './forms/purchase-form/purchase-form.component';
import { RedeemHistoryComponent } from './forms/redeem-history/redeem-history.component';
import { SalesFormComponent } from './forms/sales-form/sales-form.component';
import { StockAdjustmentComponent } from './forms/stock-adjustment/stock-adjustment.component';
import { UploadPurchaseComponent } from './forms/upload-purchase/upload-purchase.component';
import { BankComponent } from './master/bank/bank.component';
import { ChequeComponent } from './master/cheque/cheque.component';
import { DistributorComponent } from './master/distributor/distributor.component';
import { DoctorComponent } from './master/doctor/doctor.component';
import { DosageComponent } from './master/dosage/dosage.component';
import { GeneralDistributorsComponent } from './master/general-distributors/general-distributors.component';
import { GenericComponent } from './master/generic/generic.component';
import { HsnComponent } from './master/hsn/hsn.component';
import { ManufacturerComponent } from './master/manufacturer/manufacturer.component';
import { MasterDashboardComponent } from './master/master-dashboard/master-dashboard.component';
import { PatientComponent } from './master/patient/patient.component';
import { PoReportAutoComponent } from './master/po-report-auto/po-report-auto.component';
import { PoReportCustomiseComponent } from './master/po-report-customise/po-report-customise.component';
import { ProductComponent } from './master/product/product.component';
import { PoCustomizeComponent } from './master/purchase_order/po-customize/PoCustomizeComponent';
import { ShelfComponent } from './master/shelf/shelf.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { AllReportsComponent } from './reports/all-reports/all-reports.component';
import { CategoryPurchaseComponent } from './reports/category-purchase/category-purchase.component';
import { CategorySalesComponent } from './reports/category-sales/category-sales.component';
import { GeneralPurchaseViewComponent } from './reports/general-purchase-view/general-purchase-view.component';
import { GeneralSalesViewComponent } from './reports/general-sales-view/general-sales-view.component';
import { ProductPurchaseComponent } from './reports/product-purchase/product-purchase.component';
import { ProductSaleComponent } from './reports/product-sale/product-sale.component';
import { PurchaseReportComponent } from './reports/purchase-report/purchase-report.component';
import { SalesReportComponent } from './reports/sales-report/sales-report.component';
import { StockReportComponent } from './reports/stock-report/stock-report.component';
import { SalesReturnComponent } from './sales-return/sales-return.component';
import { SalesComponent } from './sales/sales.component';
import { UserAssignComponent } from './user-assign/user-assign.component';
import { SalesmanComponent } from './master/salesman/salesman.component';
import { WsalesComponent } from './forms/wsales/wsales.component';
import { SchememasterComponent } from './master/schememaster/schememaster.component';
import { HospitalComponent } from './master/hospital/hospital.component';
import { IpdComponent } from './forms/ipd/ipd.component';
import { NewAllReportComponent } from './reports/new-all-report/new-all-report.component';
import { MarginComponent } from './master/margin/margin.component';
import { WsalesreturnComponent } from './wsalesreturn/wsalesreturn.component';
import { DebitnoteComponent } from './debitnote/debitnote.component';
import { RetmarginComponent } from './retmargin/retmargin.component';


@NgModule({
  imports: [
    ModalModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    NgbModule,
    FormsModule,
    SelectModule,
    HttpClientModule,
    HotkeyModule.forRoot()
   
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    PurchaseComponent,
    PurchaseFormComponent,
    SalesComponent,
    SalesFormComponent,
    HsnComponent,
    ShelfComponent,
    PatientComponent,
    ManufacturerComponent,
    DistributorComponent,
    ProductComponent,
    DosageComponent,
    GenericComponent,
    PoCustomizeComponent,
    BankComponent,
    ChequeComponent,
    PoReportCustomiseComponent,
    PoReportAutoComponent,
    SalesReportComponent,
    PurchaseReportComponent,
    CategoryPurchaseComponent,
    ProductPurchaseComponent,
    ProductSaleComponent,
    CategorySalesComponent,
    CustomerPaymentComponent,
    DistributorPaymentComponent,
    DebitNoteComponent,
    SalesReturnComponent,
    DailyClosingComponent,
    StockReportComponent,
    UserAssignComponent,
    MasterDashboardComponent,
    AllReportsComponent,
    DoctorComponent,
    OpeningStockComponent,
    StockAdjustmentComponent,
    AccountComponent,
    UploadPurchaseComponent,
    ExpenseComponent,
    AdjustGSTComponent,
    AdjustSalesGSTComponent,
    AdjustPurchaseGSTComponent,
    RedeemHistoryComponent,
    CardActivationComponent,
    CardDetailsComponent,
    MedicalStoresComponent,
    PurchaseOrderComponent,
    UploadProductComponent,
    GeneralPurchaseComponent,
    GeneralSalesComponent,
    GeneralSalesViewComponent,
    GeneralPurchaseViewComponent,
    GeneralComponent,
    GeneralDistributorsComponent,
    ReceivedOrdersComponent,
    GenerateBarcodeComponent,
    LoanMasterComponent,
    InvestmentMasterComponent,
    AssetsMasterComponent,
    LedgerComponent,
    PaymentsComponent,
    MerchantStoresComponent,
    HomePageComponent,
    SalesmanComponent,
    WsalesComponent,
    SchememasterComponent,
    HospitalComponent,
    IpdComponent,
    NewAllReportComponent,
    MarginComponent,
    WsalesreturnComponent,
    DebitnoteComponent,
    RetmarginComponent
  ],
  providers: [
    DataAccessService,
    {
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
    
  }],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
