import { Component, OnInit } from '@angular/core';
import { DataAccessService } from '../../data-access.service';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.scss']
})
export class SalesReportComponent implements OnInit {
  sales;
  sales1 = [];
  from_date = '';
  to_date = '';
  customer_name = '';
  doctor_name = '';
  selectedOrder;
  isFirst = false;
  constructor(private service: DataAccessService, private router: Router) { }

  ngOnInit() {
    this.getSales();
  }


  custType = 'Retail';

  getSales() {
    this.service.get('getdata.php?type=getSales').subscribe(data => {
      this.sales = data;
      this.sales1 = this.sales;
      const element1 = document.getElementById('filter') as HTMLElement;
      element1.focus();
    });
  }

  viewOrder(index, value) {
    this.selectedOrder = this.sales1[index];
    if (value === 'view') {
      this.isFirst = true;
    } else if (value === 'pdf') {
      window.open(this.service.url1 + 'print/sales_bill.php?order_no=' + index + '&client_no=' + localStorage.getItem('id'), '_blank');
      // window.open(this.service.url1 + 'sales.php?type=orderPDF&order_no=' + index + '&id=' + localStorage.getItem('id'), '_blank');
    } else if (value === 'print') {
      // tslint:disable-next-line:max-line-length
      window.open(this.service.url1 + 'sales_print.php?order_no=' + index + '&id=' + localStorage.getItem('id') + '&user=' + localStorage.getItem('cl_saler_name'), '_blank');
    }
  }

  filterResult() {
    this.sales1 = [];
    let index = 0;
      const length = Object.keys(this.sales).length;
      if (this.from_date !== '' && this.to_date === '' && this.customer_name === '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          if (this.sales[i].order_date >= this.from_date) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          if (this.sales[i].order_date <= this.to_date) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date === '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date === '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          if (this.sales[i].order_date >= this.from_date && this.sales[i].order_date <= this.to_date) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date === '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (this.sales[i].order_date >= this.from_date && test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date === '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (this.sales[i].order_date >= this.from_date && test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date !== '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (this.sales[i].order_date <= this.to_date && test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (this.sales[i].order_date <= this.to_date && test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date === '' && this.to_date === '' && this.customer_name !== '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          const test1 = this.sales[i].doctor_name;
          if (test1.includes(this.customer_name) && test1.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date !== '' && this.customer_name !== '' && this.doctor_name === '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].patient_name;
          if (this.sales[i].order_date >= this.from_date && this.sales[i].order_date <= this.to_date && test.includes(this.customer_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else if (this.from_date !== '' && this.to_date !== '' && this.customer_name === '' && this.doctor_name !== '') {
        for (let  i = 0; i < length; i++) {
          const test = this.sales[i].doctor_name;
          if (this.sales[i].order_date >= this.from_date && this.sales[i].order_date <= this.to_date && test.includes(this.doctor_name)) {
            this.sales1[index] = this.sales[i];
            index++;
          }
        }
      } else {
        this.sales1 = this.sales;
      }
  }

  filterTable(value) {
    this.sales1 = [];
    let index = 0;
    const length = Object.keys(this.sales).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.sales[i].contact_no + ' ' + this.sales[i].order_no + ' ' + this.sales[i].order_date + ' ' + this.sales[i].patient_name + ' ' + this.sales[i].doctor_name + ' ' + this.sales[i].cash_credit + ' ' + this.sales[i].net).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.sales1[index] = this.sales[i];
        index++;
      }
    }
  }

  closeReport() {
    this.isFirst = false;
    const element1 = document.getElementById('filter') as HTMLElement;
    element1.focus();
  }

  viewSales() {
    this.router.navigate(['/sales']);
  }
}
