import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  aushadhmitra_card_no = '';
  customer_name = '';
  customer_address = '';
  customer_contact = '';

  available_points = 0;
  bill_amt = 0;
  earned_points = 0;
  redeem_points = 0;
  balance_points = 0;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
  }

  getPointDetails(value) {
    value = value.replace(/\s/g, '');
    this.aushadhmitra_card_no = value;
    const len = value.length;
    if (len >= 6) {
     this.service.get('aushadhmitra.php?type=getPointDetails&card_no=' + value).subscribe(response => {
        if (response['status'] === 'success') {
          this.available_points = (response['earned_points']).toFixed(2);
          this.customer_name = response['customer_name'];
          this.customer_contact = response['contact_no'];
          this.customer_address = response['address'];
        } else {
          this.aushadhmitra_card_no = '';
          alert('CARD NUMBER IS NOT VALID');
        }
     });
    }
  }

  calculation() {
    this.earned_points = parseFloat(((this.bill_amt * 30) / 100).toFixed(2));

    const point_redeem = parseFloat(((this.bill_amt * 12) / 100).toFixed(2));
    if (this.available_points >= point_redeem) {
      this.redeem_points = point_redeem;
    } else {
      this.redeem_points = this.available_points;
    }
    this.balance_points = this.earned_points + (this.available_points - this.redeem_points);
  }

  savePoints(data) {
    this.service.post('aushadhmitra.php?type=savePoints', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'success') {
        this.aushadhmitra_card_no = '';
        this.customer_name = '';
        this.customer_address = '';
        this.customer_contact = '';
        this.available_points = 0;
        this.bill_amt = 0;
        this.earned_points = 0;
        this.redeem_points = 0;
        this.balance_points = 0;
        alert('BILL HAS BEEN SAVED SUCCESSFULLY');
      } else {
        alert('AN ERROR OCCURED, PLEASE TRY AGAIN');
      }
    });
  }

}
