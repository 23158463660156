import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-received-orders',
  templateUrl: './received-orders.component.html',
  styleUrls: ['./received-orders.component.scss']
})
export class ReceivedOrdersComponent implements OnInit {
  prescriptions;
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getPrescriptions();
  }

  getPrescriptions() {
    this.service.get('aushadhmitra.php?type=getPrescriptions').subscribe(response => {
      this.prescriptions = response;

      const len = Object.keys(this.prescriptions).length;
      for (let i = 0; i < len; i++) {
        const temp = this.prescriptions[i];
        if (temp.medical1_status === 'pending' && temp.medical2_status === 'pending' && temp.medical3_status === 'pending' && temp.medical4_status === 'pending' && temp.medical5_status === 'pending') {
          this.prescriptions[i].medicals = 'pending';
        } else if (temp.medical1_status === 'active') {
          this.prescriptions[i].medical_active = 'yes';
          this.prescriptions[i].medical_id = temp['medical1'];
        } else if (temp.medical2_status === 'active') {
          this.prescriptions[i].medical_active = 'yes';
          this.prescriptions[i].medical_id = temp['medical2'];
        } else if (temp.medical3_status === 'active') {
          this.prescriptions[i].medical_active = 'yes';
          this.prescriptions[i].medical_id = temp['medical3'];
        } else if (temp.medical4_status === 'active') {
          this.prescriptions[i].medical_active = 'yes';
          this.prescriptions[i].medical_id = temp['medical4'];
        } else if (temp.medical5_status === 'active') {
          this.prescriptions[i].medical_active = 'yes';
          this.prescriptions[i].medical_id = temp['medical5'];
        }
        if (this.prescriptions[i].medical_active === 'yes' && this.prescriptions[i].medical_id === localStorage.getItem('id')) {
          this.prescriptions[i].isbill = 'No';
        }
      }
    });
  }

  downloadImage(url) {
    window.open(url, '_blank');
  }

  acceptOrder(order_no) {
    this.service.get('aushadhmitra.php?type=acceptPrescription&order_no=' + order_no).subscribe(response => {
      if (response['status'] === 'success') {
        this.getPrescriptions();
        alert('Order has been accepted.');
      } else {
        alert('An error Occured, Please try again!');
      }
    });
  }

  generatebill() {
    alert('UNDER MAINTENANCE');
  }

}
