import { DataAccessService } from './../../data-access.service';
import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent implements OnInit{
  temp = {};
  username;
  password;
  remember_me = false;

  role;
  isSalesman = false;
  isAdmin = false;
  isPurchase = false;
  constructor(private service: DataAccessService, private _hotkeysService: HotkeysService, private router: Router) {
    this.role = localStorage.getItem('role');
  }

  ngOnInit() {
  }

  checkLogin(data) {
    this.service.post('login.php?type=checklogin', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] === 'active') {
        localStorage.setItem('remember', this.remember_me + '');
        localStorage.setItem('role', response['role']);
        localStorage.setItem('email', response['email']);
        localStorage.setItem('name', response['name']);
        localStorage.setItem('state', response['state']);
        localStorage.setItem('clientType', response['clientType']);
        localStorage.setItem('id', response['id']);
        localStorage.setItem('isgeneral', response['isgeneral']);
        localStorage.setItem('cl_saler_name', response['cl_saler_name']);
        if (localStorage.getItem('role') === 'Salesman') {
          this.router.navigate(['/sales']);
        } else if (localStorage.getItem('role') === 'Purchase') {
          this.router.navigate(['/new-purchase']);
        } else if (localStorage.getItem('role') === 'Admin') {
          this.router.navigate(['/']);
        }
      } else {
        alert(response['message']);
      }
    });
  }

  checkLogin1() {
    this.service.post('login.php?type=checklogin', JSON.stringify(this.temp)).subscribe(response => {
      if (response['status'] === 'active') {
        localStorage.setItem('role', response['role']);
        localStorage.setItem('email', response['email']);
        localStorage.setItem('name', response['name']);
        localStorage.setItem('id', response['id']);
        localStorage.setItem('cl_saler_name', response['cl_saler_name']);
        this.router.navigate(['/']);
      } else {
        alert('Invalid Login Credentials');
      }
    });
  }

}
