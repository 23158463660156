import { DataAccessService } from './../../data-access.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medical-stores',
  templateUrl: './medical-stores.component.html',
  styleUrls: ['./medical-stores.component.scss']
})
export class MedicalStoresComponent implements OnInit {
  medicalStores;
  medicalStores1 = [];
  constructor(private service: DataAccessService) { }

  ngOnInit() {
    this.getMedicalStores();
  }

  getMedicalStores() {
    this.service.get('aushadhmitra.php?type=getMedicalStores').subscribe(response => {
      this.medicalStores = response;
      this.medicalStores1 = this.medicalStores;
    });
  }

  searchMedical(value) {
    this.medicalStores1 = [];
    let index = 0;
    let len = Object.keys(this.medicalStores).length;
    for (let i = 0; i < len; i++) {
      // tslint:disable-next-line:max-line-length
      let temp = this.medicalStores[i].cl_name + ' ' + this.medicalStores[i].cl_add + ' ' + this.medicalStores[i].cl_name1 + ' ' + this.medicalStores[i].cl_dl + ' ' + this.medicalStores[i].cl_gst + ' ' + this.medicalStores[i].cl_email + ' ' + this.medicalStores[i].cl_saler_name;
      if (temp.includes(value)) {
        this.medicalStores1[index] = this.medicalStores[i];
        index++;
      }
    }
  }

}
