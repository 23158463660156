import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  isDistributor = true;
  constructor() { }

  ngOnInit() {
  }

  checkButton(value) {
    if (value === 'distributor') {
      this.isDistributor = true;
    } else {
      this.isDistributor = false;
    }
  }

}
