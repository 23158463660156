import { DataAccessService } from './../data-access.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit {
  purchases;
  purchases1 = [];
  selectedPurchase;
  isShow = false;
  constructor(private service: DataAccessService, private router: Router) {
   }

  ngOnInit() {
    this.getPurchases();
  }

  getPurchases() {
    this.service.get('purchase.php?type=getPurchases')
    .subscribe(response => {
      this.purchases = response;
      this.purchases1 = this.purchases;
      const element1 = document.getElementById('filter') as HTMLElement;
      element1.focus();
    });
  }

  getSelectedProducts(index) {
    const len = Object.keys(this.purchases1).length;
    for (let i = 0; i < len; i++) {
      if (this.purchases1[i].po_no === index) {
        this.selectedPurchase = this.purchases1[i];
        this.isShow = true;
      }
    }
  }

  closeForm() {
    this.isShow = false;
  }

  filterTable(value) {
    this.purchases1 = [];
    let index = 0;
    const length = Object.keys(this.purchases).length;
    for (let i = 0; i < length; i++) {
      // tslint:disable-next-line:max-line-length
      const tempstring = (this.purchases[i].invoice_no + ' ' + this.purchases[i].invoice_date + ' ' + this.purchases[i].distributor_name + ' ' + this.purchases[i].net_total + ' ' + this.purchases[i].tax_total).toLowerCase();
      value = value.toLowerCase();
      if ((tempstring).indexOf(value) > -1 ) {
        this.purchases1[index] = this.purchases[i];
        index++;
      }
    }
  }

  printQRcode(qty, p_no, batch_no, p_name, mrp) {
    const string = p_name + '$' + batch_no;
    // tslint:disable-next-line:max-line-length
    window.open(this.service.url1 + 'barcode.php?id=' + localStorage.getItem('id') + '&qty=' + qty + '&string=' + string + '&mrp=' + mrp, '_blank');
  }

  viewForm() {
    this.router.navigate(['/new-purchase']);
  }

}
